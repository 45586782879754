import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import LoadingButton from "../Loading button/LoadingButton";
import "./CreateAccountDetailsStyle.css";

const CreateAccountDetails = () => {
  const accountDetails = [
    {
      name: "Banking",
      id: "DEPOSIT",
      values: ["SAVINGS", "CURRENT"],
    },
    {
      name: "Banking",
      id: "TERM_DEPOSIT",
      values: "DEFAULT",
    },
    {
      name: "Banking",
      id: "RECURRING_DEPOSIT",
      values: "DEFAULT",
    },
    {
      name: "Insurance",
      id: "LIFE_INSURANCE",
      values: "DEFAULT",
    },
    {
      name: "Insurance",
      id: "INSURANCE_POLICIES",
      values: "DEFAULT",
    },
    {
      name: "InsurancePolicies",
      id: "INSURANCE_POLICIES",
      values: "DEFAULT",
    },
    {
      name: "GeneralInsurance",
      id: "GENERAL_INSURANCE",
      values: "DEFAULT",
    },
    {
      name: "LifeInsurance",
      id: "LIFE_INSURANCE",
      values: "DEFAULT",
    },
    {
      name: "General_Insurance",
      id: "GENERAL_INSURANCE",
      values: "DEFAULT",
    },
    {
      name: "General_Insurance",
      id: "INSURANCE_POLICIES",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "EQUITIES",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "MUTUAL_FUNDS",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "IDR",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "CIS",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "ETF",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "AIF",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "NPS",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "SIP",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "INVIT",
      values: "DEFAULT",
    },
    {
      name: "Eq&Mf",
      id: "REIT",
      values: "DEFAULT",
    },
    {
      name: "Gst",
      id: "GSTR1_3B",
      values: "DEFAULT",
    }
  ];

  let [formData, setFormData] = useState({
    contactNumber: "",
    fipId: "",
    fiType: "",
    accountType: "",
    birthDate: format(
      new Date(1966,5,8),
      "yyyy-MM-dd"
    ),
    startDate: format(
      new Date().setFullYear(new Date().getFullYear() - 1),
      "yyyy-MM-dd"
    ),
    endDate: format(new Date(), "yyyy-MM-dd"),
  });

  const getNameFromAccountDetails = (fipId) => {
    let name;
    if (fipId === "BARB0KIMXXX" || fipId === "dhanagarbank")
      name = "Banking";
    else if (fipId === "fip@finsure")
      name = "Insurance";
    else if (fipId === "fip@inspol")
      name = "InsurancePolicies";
    else if (fipId === "fip@general")
      name = "GeneralInsurance";
    else if (fipId === "fip@life")
      name = "LifeInsurance";
    else if (fipId === "fip@finsuregeneral")
      name = "General_Insurance"
    else if (fipId === "fip@finrepo")
      name = "Eq&Mf"
    else if (fipId === "fip@finvugst")
      name = "Gst"
    return name;
  }

  const fiTypeList = (fipId) => {
    const filteredOptions = accountDetails
      .filter((item) => item.name === getNameFromAccountDetails(fipId))
      .map((item) => item.id);
    return filteredOptions;
  };

  const accountTypeList = (fipId, fiType) => {
    const filteredOption = accountDetails.find((item) => item.id === fiType && item.name === getNameFromAccountDetails(fipId));
    if (filteredOption) {
      return Array.isArray(filteredOption.values) ? filteredOption.values : [filteredOption.values];
    }
    return [];
  };

  function handleFiTypeChange(event) {
    let act;
    if (event.target.value === "DEPOSIT") act = "SAVINGS";
    else act = "DEFAULT";
    setFormData((data) => ({
      ...data,
      accountType: act,
      fiType: event.target.value,
      startDate: format(
        new Date().setFullYear(new Date().getFullYear() - 1),
        "yyyy-MM-dd"
      ),
      endDate: format(new Date(), "yyyy-MM-dd"),
    }));
  }

  const onFipChange = (event) => {
    if (event.target.value === "fip@finsure") {
      setFormData((data) => ({
        ...data,
        [event.target.name]: event.target.value,
        fiType: "LIFE_INSURANCE",
        accountType: "DEFAULT"
      }))
    }else if (event.target.value === "fip@inspol") {
      setFormData((data) => ({
        ...data,
        [event.target.name]: event.target.value,
        fiType: "INSURANCE_POLICIES",
        accountType: "DEFAULT"
      }))
    }else if (event.target.value === "fip@general") {
      setFormData((data) => ({
        ...data,
        [event.target.name]: event.target.value,
        fiType: "GENERAL_INSURANCE",
        accountType: "DEFAULT"
      }))
    }else if (event.target.value === "fip@life") {
      setFormData((data) => ({
        ...data,
        [event.target.name]: event.target.value,
        fiType: "LIFE_INSURANCE",
        accountType: "DEFAULT"
      }))
    } 
    else if (event.target.value === "fip@finrepo") {
      setFormData((data) => ({
        ...data,
        [event.target.name]: event.target.value,
        fiType: "EQUITIES",
        accountType: "DEFAULT"
      }))
    } else if (event.target.value === "fip@finsuregeneral") {
      setFormData((data) => ({
        ...data,
        [event.target.name]: event.target.value,
        fiType: "GENERAL_INSURANCE",
        accountType: "DEFAULT"
      }))
    } 
    else if (event.target.value === "fip@finvugst") {
      setFormData((data) => ({
        ...data,
        [event.target.name]: event.target.value,
        fiType: "GSTR1_3B",
        accountType: "DEFAULT"
      }))
    } else {
      setFormData((data) => ({
        ...data,
        [event.target.name]: event.target.value,
        fiType: "DEPOSIT",
        accountType: "SAVINGS"
      }))
    }
  };

  const onFormSubmit = (event) => {
    setFormData((data) => ({
      ...data,
      [event.target.name]: event.target.value,
    }));
  };

  let [errorMessage, setErrorMessage] = useState({});
  let [successMessage, setSuccessMessage] = useState();
  let [isSubmit, setIsSubmit] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage(validate(formData));
    setIsSubmit(true);
  };

  useEffect(
    () => {
      if (Object.keys(errorMessage).length === 0 && isSubmit) {
        setIsResponseLoading(true);
        addAccount()
      }
    },
    [errorMessage],
    successMessage
  );

  const validate = (formData) => {
    const errors = {};
    if (!/^([1-9]{1}[0-9]{9})$/.test(formData.contactNumber))
      errors.contactNumber = "Please enter a valid 10-digit Mobile Number!";
    if (!formData.fipId) errors.fipId = "Please select a Dummy Bank!";
    if (formData.startDate > formData.endDate)
      errors.transactionDates = "'From' Date should be before 'To' Date!";
    if ((formData.fiType === "LIFE_INSURANCE" || formData.fiType === "INSURANCE_POLICIES" || formData.fiType === "GENERAL_INSURANCE") && !formData.birthDate) errors.birthDate = "Please enter valid Birth Date!";

    return errors;
  };

  const [isResponseLoading, setIsResponseLoading] = useState(false);

  async function addAccount() {
    const hostname = window.location.hostname;
    await fetch(
      `https://${hostname}/ConnectHub/AccountManagement/Account`,
      {
        method: "POST",
        body: JSON.stringify({
          startDate: formData.startDate,
          endDate: formData.endDate,
          fipId: formData.fipId,
          fiType: formData.fiType,
          accountType: formData.accountType,
          identifiers: {
            MOBILE: formData.contactNumber,
            AADHAR: "679486011333",
            EMAIL: "info@cookiejar.in",
            DOB: formData.birthDate
          }
        }),
        headers: {
          Accept: "application/json; charset=UTF-8",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        setIsResponseLoading(false);
        return response.json();
      })
      .then((data) => {
        setSuccessMessage(
          "Account successfully created for mobile number: " +
          formData.contactNumber +
          " with account number: " +
          data["accountNo"]
        );
      });
  }

  return (
    <>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <div className="contactNumber">
          <label htmlFor="contactNumber">
            <b>Mobile Number:</b>
          </label>
          <input
            type="text"
            placeholder="Mobile Number"
            name="contactNumber"
            id="contactNumber"
            onChange={(e) => onFormSubmit(e)}
          />
        </div>
        {!/^([1-9]{1}[0-9]{9})$/.test(formData.contactNumber) && (
          <span style={{ fontSize: 14, color: "red" }}>
            {errorMessage.contactNumber}
          </span>
        )}
        <hr />
        <div className="accountData">
          <label htmlFor="dummyBank">
            <b>Dummy Bank</b>
          </label>
          <p style={{ fontSize: 11, color: "#212529" }}>
            (Select a dummy bank to create an account with)
          </p>
          <div className="fipDetails">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="BARB0KIMXXX"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">Finvu Bank(Bank Accounts)</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input" 
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="dhanagarbank"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">Dhanagar Bank(Bank Accounts)</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="fip@finsure"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">Finsure(Life Insurance Accounts)</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="fip@finsuregeneral"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">Finsure General(General Insurance Accounts)</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="fip@finrepo"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">Finrepo(Demat Accounts)</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="fip@inspol"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">FIP insurance policies(Insurance Accounts)</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="fip@general"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">General Insurance FIP(General Insurance Accounts)</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="fip@life"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">Life Insurance FIP(Life Insurance Accounts)</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fipId"
                id="fipId"
                defaultValue="fip@finvugst"
                onClick={(e) => onFipChange(e)}
              />
              <label className="form-check-label">Finvu GSTN(GST Accounts)</label>
            </div>
          </div>
          {!formData.fipId && (
            <span style={{ fontSize: 14, color: "red" }}>
              {errorMessage.fipId}
            </span>
          )}
          <div className="accountDetails" id="accountDetails">
            {formData.fipId && (
              <>
                <hr />
                <label htmlFor="dummyBank" style={{ marginBottom: '5px' }}>
                  <b>Account Details</b>
                </label>
                <div className="accDetails">
                  <div>
                    <label htmlFor="fiType">
                      <b>FI type:</b>
                    </label>
                    <select
                      name="fiType"
                      id="fiType"
                      value={formData.fiType}
                      onChange={(e) => {
                        handleFiTypeChange(e);
                      }}
                    >
                      {fiTypeList(formData.fipId).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="accountType">
                      <b>Account type:</b>
                    </label>
                    <select
                      name="accountType"
                      id="accountType"
                      value={formData.accountType}
                      onChange={(e) => {
                        onFormSubmit(e);
                      }}
                    >
                      {accountTypeList(formData.fipId, formData.fiType).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <hr />
        {formData.fipId && (formData.fiType === "LIFE_INSURANCE" || formData.fiType === "INSURANCE_POLICIES" || formData.fiType === "GENERAL_INSURANCE") && (
          <>
            <div className="birthDate">
              <label htmlFor="birthDate">
                <b>Personal Information</b>
              </label>
              {(
                <div>
                  <label htmlFor="birthDate">
                    <b>Birth Date:</b>
                  </label>
                  <input
                    type="date"
                    name="birthDate"
                    id="birthDate"
                    value={formData.birthDate}
                    onChange={(e) => onFormSubmit(e)}
                  />
                </div>
              )}

            </div>
            {formData.birthDate === "" && (
              <span style={{ fontSize: 14, color: "red" }}>
                {errorMessage.birthDate}
              </span>
            )}
            <hr />
          </>
        )}
        
        {formData.fipId && (formData.fiType === "DEPOSIT" || formData.fiType === "RECURRING_DEPOSIT") && (
          <>
            <div className="transactionDates">
              <label htmlFor="transactionDates">
                <b>Generate Transactions</b>
              </label>
              {formData.fiType === "DEPOSIT" && (
                <div>
                  <label htmlFor="startDate">
                    <b>From:</b>
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    id="startDate"
                    value={formData.startDate}
                    onChange={(e) => onFormSubmit(e)}
                  />
                  <label htmlFor="endDate">
                    <b>To:</b>
                  </label>
                  <input
                    type="date"
                    name="endDate"
                    id="endDate"
                    value={formData.endDate}
                    onChange={(e) => onFormSubmit(e)}
                  />
                </div>
              )}
              {formData.fiType === "RECURRING_DEPOSIT" && (
                <div>
                  <label htmlFor="endDate">
                    <b>From:</b>
                  </label>
                  <input
                    type="date"
                    name="endDate"
                    id="endDate"
                    value={formData.endDate}
                    onChange={(e) => onFormSubmit(e)}
                  />
                  <label htmlFor="startDate">
                    <b>For:</b>
                  </label>
                  <select
                    name="startDate"
                    id="startDateRD"
                    onChange={(e) => onFormSubmit(e)}
                  >
                    <option
                      value={format(
                        new Date().setFullYear(new Date().getFullYear() - 1),
                        "yyyy-MM-dd"
                      )}
                    >
                      1 Year
                    </option>
                    <option
                      value={format(
                        new Date().setFullYear(new Date().getFullYear() - 2),
                        "yyyy-MM-dd"
                      )}
                    >
                      2 Year
                    </option>
                    <option
                      value={format(
                        new Date().setFullYear(new Date().getFullYear() - 3),
                        "yyyy-MM-dd"
                      )}
                    >
                      3 Year
                    </option>
                  </select>
                </div>
              )}
            </div>
            {formData.startDate > formData.endDate && (
              <span style={{ fontSize: 14, color: "red" }}>
                {errorMessage.transactionDates}
              </span>
            )}
            <hr />
          </>
        )}
        <LoadingButton
          btnTitle="Create Account"
          isLoading={isResponseLoading}
          onClick={(e) => {
            handleFormSubmit(e);
          }}
        />
        <div id="manageAccounts">
          Already have an account?{" "}
          <a href="/manage-accounts">Manage accounts</a>
        </div>
      </form>
      <span style={{ fontSize: 18, color: "green" }}>{successMessage}</span>
    </>
  );
};

export default CreateAccountDetails;
